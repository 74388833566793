.loan-section {
    position: relative;
  }
  
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }
  
  .colored-background {
    width: 100%;
    
    
   
  }
  
  .red-bg {
    background-color: #ff6666;
    padding: 5;
    border-radius: 5px;
  }
  
  .blue-bg {
    background-color: #e46721;
   
    color: white;
   
  }
  .green-bg {
    background-color: #1d521b;
  
    color: white;
    
  }
  .woman-image {
    position: absolute;
    max-width: 100%;
    border-radius: 10px;

  }
  .woman-image-right {
    
    max-width: 30%;
    border-radius: 10px;

  }
  
  