.content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .celular {
    
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.2;
    
  }

  