.header {
    width: 100%;
    
  }
  
  .toolbar {
    display: flex;
    justify-content: space-between;
    
  }
  
  .logo {
    height: 100px;
    
  }
  
  .contact {
    display: flex;
   
   
  }
  .contactNoDesktop {
    display: flex;
   
   
    flex-direction: column;
    align-items: center;
   
  }
  
  .contact-info-nodesktop {
    display: flex;
    
    
    margin-top:  2px;
  }
  
  .contact-info {
    display: flex;
    
   
  }
  
  .contact-info svg {
    margin-right: 5px;
  }
  
  .social-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    
  }
  
  .solicitar-button {
    text-transform: none;
  }
  
  @media (max-width: 600px) {
    .contact {
      flex-direction: column;
      align-items: flex-end;
    }
  
    .contact-info,
    .social-buttons {
      margin: 5px 0;
    }
  }