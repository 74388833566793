.nosotros-container {
    padding: 60px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .section-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .text {
    max-width: 800px;
    text-align: justify;
    margin-bottom: 30px;
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
  }
  
  
  .image img:hover {
    transform: scale(1.05);
  }
  .image-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .image-transition-enter {
    opacity: 0;
    transform: scale(0.95);
  }
  
  .image-transition-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 500ms, transform 500ms;
  }
  
  .image {
    width: 150px;
    height: 150px;
    margin: 10px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .image:hover {
    transform: scale(1.05);
  }