.features-section {
    background-color: white;
    
  }
  
  .feature-column {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .check-icon {
    font-size: 50px;
    color: #4caf50;
    margin-right: 10px;
  }
  
  .feature-text {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    .feature-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding: 1px;
    }
    .feature-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
    }
    .feature-text {
      font-size: 16px;
    }
    
  }